<template>
    <div class="animated fadeIn">
        <b-card>
            <b-card-header>
                <i class="icon-menu mr-1"></i>Data Table Splash Screen
            </b-card-header>
            <b-card-body>
                <splash></splash>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import splash from './Table'
export default {
    name: 'splash-screens',
    components: {
        splash,
    },
}
</script>
